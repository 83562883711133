import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { productAddCardDelete } from "../../Redux/addToCard/action";
import { placeOrderCheckout } from "../../Redux/placeOrder/action";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { api } from "../../config";
import { hostListProduct, getPromoCodeDetails } from "../../Redux/actions";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import moment from "moment";
import "./Cart.css";
import { useFormik } from "formik"
import * as Yup from "yup";
import Modal from 'react-modal';

const Cart = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  //*state
  const [deliveryTotal, setDeliveryTotal] = useState(0);
  const [insuranceTotal, setInsuranceTotal] = useState(0);
  const [securityDepositTotal, setSecurityDepositTotal] = useState(0);
  const [amountPerDay, setAmountPerDay] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deliveryChargesPerMil, setDeliveryChargesPerMil] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [productId, setProductId] = useState("");
  const [isPromoCodeError, setIsPromoCodeError] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [isPromoCodeAppliedError, setIsPromoCodeAppliedError] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const [approximateFinalAmount, setApproximateFinalAmount] = useState(0); // Assuming you have approximateFinalAmount state
  const [approximateFinalAmountWithPromoCode, setApproximateFinalAmountWithPromoCode] = useState(0);
  const [promoCodeDiscountAmount, setPromoCodeDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const initialValues = {
    isRequireDelivery: false,
    address: "",
    kmPerMil: "",
  }

  const addressAndPerMilSchema = Yup.object({
    isRequireDelivery: Yup.boolean(),
    address: Yup.string().when('isRequireDelivery', {
      is: true,
      then: () =>
        Yup.string().min(2).max(100).required('Address required.'),
    }),
    kmPerMil: Yup.number()
      .when('isRequireDelivery', {
        is: true,
        then: () =>
          Yup.number().min(1).required('Please enter distance in miles'),
      }),
  })

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: addressAndPerMilSchema,
    onSubmit: (values) => {
      onClickPayNow();
    }
  })

  //*List
  const [productList, setProductList] = useState([]);

  const productArray = useSelector(state => state?.AddToCard?.productArray);
  const addToCardSuccess = useSelector(state => state?.AddToCard?.addToCardSuccess);

  const loading = useSelector(state => state?.PlaceOrderReducer?.loading);
  const success = useSelector(state => state?.PlaceOrderReducer?.success);
  const errorMessage = useSelector(state => state?.PlaceOrderReducer?.errorMessage);
  const error = useSelector(state => state?.PlaceOrderReducer?.error);
  const orderData = useSelector(state => state?.PlaceOrderReducer?.orderData);
  const user = useSelector(state => state?.HostListProduct?.user);

  const promoCodeDetailsErrorMessage = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsErrorMessage);
  const promoCodeDetailsLoading = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsLoading);
  const promoCodeDetailsSuccess = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsSuccess);
  const promoCodeDetailsError = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsError);
  const promoCodeDetailsOrderData = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsOrderData);


  useEffect(() => {
    dispatch(hostListProduct({ page: 1 }));
  }, []);

  const total = useMemo(() => {
    let calculateTotal = insuranceTotal + amountPerDay + (values.kmPerMil * deliveryTotal);
    setTotalAmount(calculateTotal);
    return calculateTotal;
  }, [insuranceTotal, amountPerDay, values.kmPerMil, deliveryTotal]);

  useEffect(() => {
    if (values.isRequireDelivery === false) {
      resetForm({});
    }
  }, [values.isRequireDelivery]);


  useEffect(() => {
    if (productArray.length > 0) {
      setStartDate(moment(productArray[0].productPickupDate).format("MM/DD/YYYY"));
      setEndDate(moment(productArray[0].productReturnDate).format("MM/DD/YYYY"));
      setProductList(productArray);
      var deliveryTotal = productArray.map((item) => item.delivery_charge);
      deliveryTotal = deliveryTotal.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      setDeliveryTotal(deliveryTotal);
      var insuranceTotal = productArray.map((item) => item.insurance_amount);

      insuranceTotal = insuranceTotal.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      setInsuranceTotal(insuranceTotal);
      var securityDepositTotal = productArray.map(
        (item) => item.security_deposit
      );
      securityDepositTotal = securityDepositTotal.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      setSecurityDepositTotal(securityDepositTotal);
      setAmountPerDay(productArray[0].approximateFinalAmount);

      var approximateFinalAmountTotal = productArray.map(
        (item) => item.approximateFinalAmount
      );
      approximateFinalAmountTotal = approximateFinalAmountTotal.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      setApproximateFinalAmount(approximateFinalAmountTotal);
      setApproximateFinalAmountWithPromoCode(approximateFinalAmountTotal);


    } else {
      setProductList([]);
      setDeliveryTotal(0);
      setInsuranceTotal(0);
      setAmountPerDay(0);
      setSecurityDepositTotal(0);
      setApproximateFinalAmount(0);
    }
  }, [productArray, addToCardSuccess]);

  useEffect(() => {
    if (!loading && orderData) {
      // toast.success("Order Place Successfully.");
      let stripeCheckoutURL = orderData.url;
      // Open the Stripe checkout URL in a new tab
      // window.location.href = stripeCheckoutURL;
      window.open(stripeCheckoutURL, "_self");
    }
  }, [success, orderData]);

  useEffect(() => {
    if (!loading) {
      toast.error(errorMessage);
    }
  }, [errorMessage, error]);

  useEffect(() => {
    if (hasAttemptedSubmit && promoCodeDetailsOrderData?.promocode.status === true && !isPromoCodeExpired(promoCodeDetailsOrderData?.promocode.startDate, promoCodeDetailsOrderData?.promocode.endDate)) {
      setIsPromoCodeAppliedError(false);
      toast.success("Promo code applied successfully.");
      setIsPromoCodeAppliedError(false);
      const calculatePerAmount = totalAmount - (amountPerDay * promoCodeDetailsOrderData.promocode.discount / 100);
      setTotalAmount(calculatePerAmount)
      const calculateDiscountAmount = total - calculatePerAmount;
      setPromoCodeDiscountAmount(calculateDiscountAmount);
    } else if (promoCodeDetailsOrderData?.promocode.status === false) {
      toast.error("Promo code is not active.");
      setIsPromoCodeAppliedError(true);
    } else if (isPromoCodeExpired(promoCodeDetailsOrderData?.promocode.startDate, promoCodeDetailsOrderData?.promocode.endDate)) {
      toast.error("Promo code is expire.");
      setIsPromoCodeAppliedError(true);
    }
  }, [hasAttemptedSubmit, promoCodeDetailsOrderData, approximateFinalAmount]);

  useEffect(() => {
    if (!promoCodeDetailsLoading) {
      toast.error(promoCodeDetailsErrorMessage);
    }
  }, [promoCodeDetailsErrorMessage, promoCodeDetailsError]);

  const isPromoCodeExpired = (startDate, endDate) => {
    const currentDate = new Date();
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    return currentDate < startDateObj || currentDate > endDateObj;
  };

  const handleClickPromoCodeSubmit = () => {
    if (promoCode.trim() === '') {
      setIsPromoCodeError(true);
  
      // If a promo code is already added, do not allow adding another promo code by submitting a blank one
      if (hasAttemptedSubmit) {
        toast.error("Cannot submit a blank promo code.");
        return false;
      }
  
      setHasAttemptedSubmit(false);
      return false;
    } 
    else if (
      hasAttemptedSubmit &&
      promoCodeDetailsOrderData?.promocode.status === true &&
      !isPromoCodeExpired(
        promoCodeDetailsOrderData?.promocode.startDate,
        promoCodeDetailsOrderData?.promocode.endDate
      )
    ) {
      toast.error("Promo code is already in use.");
      setIsPromoCodeError(false);
      return false;
    } 
    else {
      // Perform submission logic
      // Assuming promoCodeDetailsSuccess indicates successful retrieval of promo code details
      setHasAttemptedSubmit(true);
      setIsPromoCodeError(false);
      dispatch(getPromoCodeDetails({ promo_code: promoCode, type: "product" }));
    }
  };

  //*onClick Remove Product
  const onClickRemoveProduct = (productId) => {
    dispatch(productAddCardDelete(productId));
    // navigation("/cart");
  };

  //*onClick Pay Now
  const onClickPayNow = () => {
    if (productList.length > 0) {
      let productItemArray = [];
      productList.map((item, index) => {
        productItemArray.push({
          product_id: item._id,
          pickup_date: item.productPickupDate,
          pickup_time: item.productPickupTime,
          return_date: item.productReturnDate,
          return_time: item.productReturnTime,
          who_captain: item.who_captain,
        });

        if (index === productList.length - 1) {
          let token = localStorage.getItem("accessToken");
          if (token === null) {
            toast.error("Please login.");
            navigation("/login");
          } else {
            let dataObj;
            if (values.isRequireDelivery) {
              dataObj = {
                product_item: productItemArray,
                order_total_amount: totalAmount.toFixed(2),
                order_sub_total_amount: totalAmount.toFixed(2),
                promo_code: promoCode,
                discount_amount: promoCodeDiscountAmount.toFixed(2),
                deposit_amount: securityDepositTotal,
                is_delivery: values.isRequireDelivery,
                delivery_address: values.isRequireDelivery ? values.address : "",
                delivery_distance: values.isRequireDelivery ? values.kmPerMil : undefined,
                delivery_charge: deliveryChargesPerMil,
                insurance_Amount: insuranceTotal
              };
            } else {
              dataObj = {
                product_item: productItemArray,
                order_total_amount: totalAmount.toFixed(2),
                order_sub_total_amount: totalAmount.toFixed(2),
                promo_code: promoCode,
                discount_amount: promoCodeDiscountAmount.toFixed(2),
                deposit_amount: securityDepositTotal,
                is_delivery: values.isRequireDelivery,
                delivery_address: values.isRequireDelivery ? values.address : "",
                delivery_charge: deliveryChargesPerMil,
                insurance_Amount: insuranceTotal
              };
            }
            dispatch(placeOrderCheckout(dataObj));
          }
        }
      });
    } else {
      toast.error("Please add at least one product.");
    }
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: user?.docs.length === 1 ? 1 : user?.docs.length === 2 ? 2 : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1029,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/productdetails/${id}`);
  };

  const handleClickTotal = async (value) => {
    // Validate the input
    let deliveryChargePerMil = value * deliveryTotal;
    setDeliveryChargesPerMil(deliveryChargePerMil);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Header />

        <section className="innerBanner-wrapper">
          <div className="innerBanner-img img-obj cover">
            <img src="images/header.png" alt="header_image" />
          </div>
          <div className="container h-100 z1 flex-all">
            <h1>Your Cart</h1>
          </div>
        </section>
        {/* Profile */}
        <section className="py-wrapper">
          <div className="container">
            <div className="row g-4">
              <div className="col cart-main">
                <div className="table-responsive">
                  <table className="table-themeBr table-cart">
                    <thead>
                      <tr>
                        <th>PRODUCT</th>
                        <th>LOCATION</th>
                        <th>PRICE</th>
                        <th>Approximate Amount</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productList.length > 0 &&
                        productList.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Link to={`/productdetails/${item?._id}`} href="#">
                                  <div className="d-inline-flex align-items-center">
                                    <div className="ratio">
                                      <img
                                        src={
                                          item?.images_detail[0]?.path
                                        }
                                        alt={item?.uniqueId + "_" + index}
                                        onError={(event) => {
                                          event.target.src =
                                            "/images/default_image.webp";
                                          event.onerror = null;
                                        }}
                                      /></div>
                                    <div>
                                      {item?.product_name?.length > 15 ? (
                                        <p className="header-break-word">{item?.product_name}</p>
                                      ) : (
                                        <p>{item?.product_name}</p>
                                      )}
                                    </div>
                                  </div>
                                </Link>
                              </td>
                              <td onClick={() => {
                                navigate(`/productdetails/${item?._id}`);
                              }}>{item?.product_location[0]}</td>
                              <td onClick={() => {
                                navigate(`/productdetails/${item?._id}`);
                              }}>
                                ${item?.price_per_day}
                                <span>/ Day</span>
                              </td>
                              <td onClick={() => {
                                navigate(`/productdetails/${item?._id}`);
                              }}>
                                $
                                {item?.approximateFinalAmount +
                                  " for " +
                                  item?.totalDays +
                                  " Days"}
                              </td>
                              <td onClick={() => {
                                navigate(`/productdetails/${item?._id}`);
                              }}>
                                {moment(item?.productPickupDate).format("MM/DD/YYYY")}
                              </td>
                              <td onClick={() => {
                                navigate(`/productdetails/${item?._id}`);
                              }}>
                                {moment(item?.productReturnDate).format("MM/DD/YYYY")}
                              </td>
                              <td>
                                <Link
                                  onClick={() => {
                                    setShowModal(true);
                                    setProductId(item?.uniqueId);
                                  }}
                                  className="btn btn-danger btn-icon"
                                  //  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 22"
                                  >
                                    <g transform="translate(-2 -1)">
                                      <path
                                        d="M3,6H21"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                      />
                                      <path
                                        d="M19,6V20a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V6M8,6V4a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V6"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                      />
                                      <line
                                        y2="6"
                                        transform="translate(10 11)"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                      />
                                      <line
                                        y2="6"
                                        transform="translate(14 11)"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </svg>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-auto">
                <div className="price-details">
                  {
                    productList.length > 0 &&
                    <div className="d-flex align-items-center pt-2 mb-3 gap-5">
                      <h5 className="mt-3">Require Delivery</h5>
                      <div className="form-check form-switch mb-0">
                        <input type="checkbox" name="isRequireDelivery" value={values.isRequireDelivery}
                          checked={values.isRequireDelivery}
                          onChange={(e) => {
                            handleChange(e);
                          }} className="form-check-input" />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                      </div>
                    </div>
                  }
                  {
                    values.isRequireDelivery &&
                    <div>
                      <div className="text-start mb-md-5 mb-4">
                        <div className="mb-30">
                          <label htmlFor="password">
                            Address<label style={{ color: '#FF0000' }}>* </label>
                          </label>
                          <input
                            type="text"
                            name="address"
                            value={values.address}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            className={errors.address && touched.address ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Enter your address"
                          />
                          {errors.address && touched.address ? <p className="error-text">{errors.address}</p> : null}
                        </div>
                        <div className="mb-30">
                          <label htmlFor="password">
                            Miles<label style={{ color: '#FF0000' }}>* </label>
                          </label>
                          <input
                            type="number"
                            name="kmPerMil"
                            value={values.kmPerMil}
                            onChange={(e) => {
                              if ((e.target.value === '' || /^\d+$/.test(e.target.value))) {
                                if (parseInt(e.target.value) > 0 || e.target.value === '') {
                                  handleClickTotal(e.target.value)
                                  handleChange(e)
                                }
                              }
                            }}
                            onBlur={handleBlur}
                            className={errors.kmPerMil && touched.kmPerMil ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Enter miles"
                          />
                          {errors.kmPerMil && touched.kmPerMil ? <p className="error-text">{errors.kmPerMil}</p> : null}
                        </div>
                      </div>
                      <div className="d-flex align-items-center  mb-4">
                        <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                          aria-valuemax="100">
                          <div className="progress-bar" style={{ width: "30%" }}>
                            <div className="progress-value">5 mile</div>
                          </div>
                        </div>
                        <p>${deliveryChargesPerMil.toFixed(2)}</p>
                      </div>
                    </div>

                  }
                  <h5>PRICE DETAILS</h5>
                  {
                    totalAmount !== 0 &&
                    <div className="table-responsive mb-3">
                      <table className="table-themeBr">
                        <tbody>
                          {
                            deliveryChargesPerMil > 0 && values.isRequireDelivery ?
                              <tr>
                                <th>
                                  {
                                    `DELIVERY CHARGE PER MILE ( $ ${deliveryTotal.toFixed(2)} )`
                                  }
                                </th>
                                <td>${deliveryChargesPerMil.toFixed(2)}</td>
                              </tr> : null
                          }
                          <tr>
                            <th>{"Protection plan : ($" + productArray[0]?.insurance_amount_per_day + ") a day" + " * (" + productArray[0]?.totalDays + ")"}</th>
                            <td>${insuranceTotal.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <th>{"Amount per day : ($" + productArray[0]?.price_per_day + ")" + " * (" + productArray[0]?.totalDays + ")"}</th>
                            <td>${productArray[0]?.approximateFinalAmount.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <th>TOTAL:</th>
                            <td>
                              {
                                total > 10000 ?
                                  <span className="total" style={{ color: '#FF0000' }} >${total.toFixed(2)}</span> :
                                  <span className="total">${totalAmount.toFixed(2)}</span>
                              }
                            </td>
                          </tr>
                          <tr>
                            <th>Refundable deposit:</th>
                            <td>${securityDepositTotal?.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <th>Due today:</th>
                            <td>${securityDepositTotal?.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <th>Due{" (" + startDate + ")"} :</th>
                            <td>${totalAmount.toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </table>
                      <h7 className="mt-2">
                        *Refunded 48 hours after rental period is finished if no claims are submitted by the host
                      </h7>
                    </div>
                  }


                  {productList.length > 0 && totalAmount !== 0 && totalAmount < 10000 ? (
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-green"
                      >
                        <span>PAY NOW</span>
                      </button>
                    </div>
                  ) :
                    totalAmount > 10000 && total !== 0 ?
                      <span className="total" style={{ color: '#FF0000' }} >{"Your deposit total has exceeded $10,000."}</span> :
                      null
                  }<br />
                  {/* {productList.length > 0 && total !== 0 ? (
                    <h5 className="mt-2">
                      Approximate Final Amount : <b>${approximateFinalAmountWithPromoCode}{" "}</b>
                    </h5>
                  ) : null} */}

                  {
                    productList.length > 0 &&
                    <>

                      <div className="mt-4">
                        <label htmlFor="password">
                          Promo Code<label style={{ color: '#FF0000' }}>* </label>
                        </label>
                        <input
                          value={promoCode}
                          onChange={(e) => {
                            setPromoCode(e.target.value);
                          }}
                          className={isPromoCodeError ? 'form-control form-control-error' : 'form-control'}
                          placeholder="Enter Promo Code"
                        />
                        {isPromoCodeError ? <p className="error-text">{"Please Enter Promo Code"}</p> : null}
                      </div>

                      <div className="text-center mt-4 mb-2">
                        <button
                          type="button"
                          onClick={() => {
                            handleClickPromoCodeSubmit();
                          }}
                          className="btn btn-green"
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                      <div className="table-responsive mb-3">
                        <table className="table-themeBr">
                          <tbody>
                            <tr>
                              <th>Payment At:</th>
                              <td>{startDate}</td>
                            </tr>
                            <tr>
                              <th>Start Date:</th>
                              <td>{startDate}</td>
                            </tr>
                            <tr>
                              <th>End Date:</th>
                              <td>{endDate}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  }
                </div>
              </div>
              {/* {productList.length > 0 && total !== 0 ? (
              <div className="container">
                <div className="heading">
                  <h5>
                    Approximate Final Amount :- ${approximateFinalAmount}{" "}
                  </h5>
                </div>
              </div>
            ) : null} */}
            </div>
          </div>
        </section>
        {/* Product */}
        <section className="products-wrapper py-wrapper bg-lightTheme overflow-hidden">
          <div className="container">
            <div className="heading ">
              <h2>View Similar Products</h2>
            </div>
            <div className="productsNew-slider slider">
              <Slider {...settings}>
                {user?.docs &&
                  user?.docs.map((item, index) => {

                    const totalRatings = item?.reviews_data?.reduce(
                      (sum, rating) => sum + rating.rate,
                      0
                    );

                    const averageRating =
                      item?.reviews_data?.length > 0
                        ? totalRatings / item?.reviews_data?.length
                        : 0;

                    const filledStars = Math.floor(averageRating);
                    const emptyStars = 5 - filledStars;

                    const stars = [];
                    for (let i = 0; i < filledStars; i++) {
                      stars.push(
                        <i
                          key={i}
                          className="fa-solid fa-star"
                          style={{ color: "#FFA500", fontSize: "18px" }}
                        ></i>
                      );
                    }
                    for (let i = 0; i < emptyStars; i++) {
                      stars.push(
                        <i
                          key={i + filledStars}
                          className="fa-regular fa-star"
                          style={{ fontSize: "18px" }}
                        ></i>
                      );
                    }
                    return (
                      <div className="products-block bg-white" key={index}>
                        <div className="ratio cover">
                          <img
                            src={`${item?.images_detail[0]?.path}`}
                            alt={item?._id + index}
                            onError={(event) => {
                              event.target.src = "/images/default_image.webp";
                              event.onerror = null;
                            }}
                          />
                        </div>
                        <div className="text-center">
                          {item?.product_name?.length > 15 ? (
                            <h5 className="product-break-word">{item?.product_name}</h5>
                          ) : (
                            <h5>{item?.product_name}</h5>
                          )}
                          <div className="rating">
                            {stars}
                            <span>({item?.reviews_data?.length})</span>
                          </div>
                          <h4>
                            ${item?.price_per_day}
                            <span>/ Day</span>
                          </h4>
                          <button
                            onClick={() => handleClick(item._id)}
                            className="btn btn-green"
                          >
                            <span>RENT IT</span>
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </section>
        <Footer />

        {/* ___________________________Delete Modal________________________________ */}
        <Modal
          isOpen={showModal}
          onRequestClose={() => {
            setShowModal(false);
          }}
          contentLabel="Delete Confirmation"
          style={{
            content: {
              width: "500px",
              height: "250px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            },
          }}
        >
          <h2 className='my-sm-4 my-3'>Delete Product</h2>
          <p className='my-sm-4 my-3'>Are you sure you want to delete this Product?</p>
          <button className='btns btn-lg btn-danger mx-2' onClick={() => {
            onClickRemoveProduct(productId);
            setShowModal(false);
          }}><span>Delete</span></button>
          <button className='btns btn-lg btn-secondary' onClick={() => {
            setShowModal(false);
          }}><span>Cancel</span></button>
        </Modal>
        {/* ___________________________Delete Modal________________________________ */}

      </form>
    </div>
  );
};

export default Cart;
