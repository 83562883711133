import {
    SIGN_RENTER_AGREEMENT_RENTER,
    SIGN_RENTER_AGREEMENT_RENTER_SUCCESSFUL,
    SIGN_RENTER_AGREEMENT_RENTER_FAILED,
    SIGN_RENTER_AGREEMENT_RENTER_RESET
} from "./actionTypes";

const initialState = {
    renterRenterAgreementErrorMessage: null,
    renterRenterAgreementLoading: false,
    renterRenterAgreementSuccess: false,
    renterRenterAgreementError: false,
    renterRenterAgreementData: null,
};


const RenterRenterAgreementReducer = (state = initialState, action) => {

    switch (action.type) {
        case SIGN_RENTER_AGREEMENT_RENTER:
            state = {
                ...state,
                renterRenterAgreementLoading: true,
                renterRenterAgreementSuccess: false,
                renterRenterAgreementError: false,
                renterRenterAgreementErrorMessage: null,
                renterRenterAgreementData: null,
            };
            break;
        case SIGN_RENTER_AGREEMENT_RENTER_SUCCESSFUL:
            state = {
                ...state,
                renterRenterAgreementLoading: false,
                renterRenterAgreementSuccess: true,
                renterRenterAgreementErrorMessage: null,
                renterRenterAgreementError: false,
                renterRenterAgreementData: action.payload,
            };
            break;
        case SIGN_RENTER_AGREEMENT_RENTER_FAILED:
            state = {
                ...state,
                renterRenterAgreementLoading: false,
                renterRenterAgreementErrorMessage: action.payload,
                renterRenterAgreementError: true,
                renterRenterAgreementSuccess: false,
                renterRenterAgreementData: null,
            };
            break;

        case SIGN_RENTER_AGREEMENT_RENTER_RESET:
            state = {
                ...state,
                renterRenterAgreementLoading: false,
                renterRenterAgreementErrorMessage: null,
                renterRenterAgreementError: false,
                renterRenterAgreementSuccess: false,
                renterRenterAgreementData: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default RenterRenterAgreementReducer;
