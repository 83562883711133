import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // This is the default storage, but you can customize it.

//*Authentication
import RegisterUser from "./auth/register/reducer";
import LoginUser from "./auth/login/reducer"
import ForgotPassword from "./auth/forgotPassword/reducer";
import ResetPasswordReducer from "./auth/resetPassword/reducer";
import VerifyOTPReducer from "./auth/verifyOTP/reducer";
import ResendVerificationOTPReducer from "./auth/resendVerificationOTP/reducer";
import LoginWithFacebookReducer from "./auth/loginWithFacebook/reducer";

//*common
import UserProfile from "./profile/reducer";
import ProductDetail from "./product_details/reducer";
import AddToCard from "./addToCard/reducer";
import PlaceOrderReducer from "./placeOrder/reducer";
import FinalPaymentCheckoutReducer from "./finalPaymentCheckout/reducer";
import UpdateOrderStatusReducer from "./updateOrderStatus/reducer";
import SendMessageReducer from "./chat/send_Message/reducer";
import FetchMessageReducer from "./chat/fetch_Message/reducer";
import SiteSettings from "./siteSettings/reducer"
import ContactUsReducer from "./contactUs/reducer";
import GetCityReducer from "./common/getCities/reducer";

//*Renter
import RenterViewPayment from "./renter/viewPayment/reducer";
import RenterOrderReducer from "./renter/orderList/reducer";
import RenterOrderDetailReducer from "./renter/orderDetails/reducer";
import RenterAddReviewReducer from "./renter/addReview/reducer";
import RenterUpdateReviewReducer from "./renter/updateReview/reducer";
import RenterRenterAgreementReducer from "./renter/updateRenterSignAgreement/reducer";

//*Host
import HostViewPaymentReducer from "./host/viewPayment/reducer";
import HostListProduct from "./host_redux/reducer";
import HostOrderReducer from "./host/orderList/reducer";
import HostOrderDetailReducer from "./host/orderDetails/reducer";
import HostAddReviewReducer from "./host/addReview/reducer";
import HostUpdateReviewReducer from "./host/updateReview/reducer";
import HostRenterAgreementReducer from "./host/updateRenterSignAgreement/reducer";
import HostGetProtectionPlanReducer from "./host/getProtectionProof/reducer";
import HostDeleteProtectionPlanReducer from "./host/deleteProtectionProof/reducer";


//*Host_Product_crud
import HostProductCrud from "./host_product_crud/reducer";

//*PromoCode
import PromoCodeReducer from "./promoCode/reducer";
import AddPromoCodeReducer from "./addPromoCode/reducer";


const persistConfig = {
    key: "addToCard", // This is the key for the persisted state in storage.
    storage,
    whitelist: ["AddToCard", "SiteSettings"],
};

const rootReducer = combineReducers({

    RegisterUser,
    LoginUser,
    HostListProduct,
    ForgotPassword,
    ResetPasswordReducer,
    VerifyOTPReducer,
    ResendVerificationOTPReducer,
    LoginWithFacebookReducer,
    UserProfile,
    ProductDetail,
    AddToCard,
    PlaceOrderReducer,
    FinalPaymentCheckoutReducer,
    UpdateOrderStatusReducer,
    SendMessageReducer,
    FetchMessageReducer,
    SiteSettings,
    ContactUsReducer,
    GetCityReducer,

    RenterViewPayment,
    RenterOrderReducer,
    RenterOrderDetailReducer,
    RenterAddReviewReducer,
    RenterUpdateReviewReducer,
    RenterRenterAgreementReducer,

    HostViewPaymentReducer,
    HostOrderReducer,
    HostOrderDetailReducer,
    HostAddReviewReducer,
    HostUpdateReviewReducer,
    HostProductCrud,
    HostRenterAgreementReducer,
    HostGetProtectionPlanReducer,
    HostDeleteProtectionPlanReducer,

    PromoCodeReducer,
    AddPromoCodeReducer
});

// Wrap the rootReducer with persistReducer to apply the configuration.
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;