import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
    SIGN_RENTER_AGREEMENT_RENTER,
} from "./actionTypes";
import { signRenterAgreementRenterSuccessful, signRenterAgreementRenterFailed, } from "./action";
import { postUpdateOrderStatusSignRenterAgreement, } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* getRenterAgreementDetails({ payload: { user } }) {

    try {
        const response = yield call(postUpdateOrderStatusSignRenterAgreement, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(signRenterAgreementRenterSuccessful(response.data));
        } else {
            yield put(signRenterAgreementRenterFailed(response.message));
        }
    } catch (error) {
        yield put(signRenterAgreementRenterFailed(error));
    }
}

export function* watchOnGetRenterRenterDetails() {
    yield takeEvery(SIGN_RENTER_AGREEMENT_RENTER, getRenterAgreementDetails);
}

function* RenterOrderRenterAgreementSaga() {

    yield all([
        fork(watchOnGetRenterRenterDetails),
    ]);
}

export default RenterOrderRenterAgreementSaga;
