import {
    SIGN_RENTER_AGREEMENT_HOST,
    SIGN_RENTER_AGREEMENT_HOST_SUCCESSFUL,
    SIGN_RENTER_AGREEMENT_HOST_FAILED,
    SIGN_RENTER_AGREEMENT_HOST_RESET
} from "./actionTypes";

const initialState = {
    hostRenterAgreementErrorMessage: null,
    hostRenterAgreementLoading: false,
    hostRenterAgreementSuccess: false,
    hostRenterAgreementError: false,
    hostRenterAgreementData: null,
};


const HostRenterAgreementReducer = (state = initialState, action) => {

    switch (action.type) {
        case SIGN_RENTER_AGREEMENT_HOST:
            state = {
                ...state,
                hostRenterAgreementLoading: true,
                hostRenterAgreementSuccess: false,
                hostRenterAgreementError: false,
                hostRenterAgreementErrorMessage: null,
                hostRenterAgreementData: null,
            };
            break;
        case SIGN_RENTER_AGREEMENT_HOST_SUCCESSFUL:
            state = {
                ...state,
                hostRenterAgreementLoading: false,
                hostRenterAgreementSuccess: true,
                hostRenterAgreementErrorMessage: null,
                hostRenterAgreementError: false,
                hostRenterAgreementData: action.payload,
            };
            break;
        case SIGN_RENTER_AGREEMENT_HOST_FAILED:
            state = {
                ...state,
                hostRenterAgreementLoading: false,
                hostRenterAgreementErrorMessage: action.payload,
                hostRenterAgreementError: true,
                hostRenterAgreementSuccess: false,
                hostRenterAgreementData: null,
            };
            break;

        case SIGN_RENTER_AGREEMENT_HOST_RESET:
            state = {
                ...state,
                hostRenterAgreementLoading: false,
                hostRenterAgreementErrorMessage: null,
                hostRenterAgreementError: false,
                hostRenterAgreementSuccess: false,
                hostRenterAgreementData: null,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default HostRenterAgreementReducer;
