import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, loginResetState } from "../../Redux/auth/login/action";
import { forgotPasswordReset } from "../../Redux/auth/forgotPassword/action";
import { toast } from "react-toastify";
import { ROLE_TYPES } from "../../helpers/enum"
import { fbLogin, fbGetUserDetails, } from "../../helpers/facebookSDK"
import { resendOTPReset } from "../../Redux/auth/resendVerificationOTP/action";
import { verifyOtpReset } from "../../Redux/auth/verifyOTP/action";
import { loginWithFacebook } from "../../Redux/auth/loginWithFacebook/action"
import Alert from 'react-bootstrap/Alert';
import { useFormik } from "formik"
import * as Yup from "yup";
import './login.css'

const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoginSucess, setIsLoginSuccess] = useState(false);
  const [selectUser, setSelectUser] = useState(ROLE_TYPES.HOST);
  const [alertMessage, setAlertMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const initialValues = {
    isEmail: "1",
    email: "",
    userPassword: "",
  }
  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const loginSchema = Yup.object({
    email: Yup.string().
      when("isEmail", {
        is: '1',
        then: () =>
          Yup.string()
            .email("Please enter valid email")
            .required("Please enter email or phone number."),
        otherwise: () =>
          Yup.string()
            .required("Please enter email or phone number.")
            .min(10, 'Phone number must be at least 10 char')
      }),
    userPassword: Yup.string().min(6).max(30).required('Please enter password.'),
  })

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      onClickLoginUser();
    }
  })

  const success = useSelector(state => state?.LoginUser?.success);
  const error = useSelector(state => state?.LoginUser?.error);
  const loginError = useSelector(state => state?.LoginUser?.loginError);
  const isUserNotFound = useSelector(state => state?.LoginUser?.isUserNotFound);
  const user = useSelector(state => state?.LoginUser?.user);

  const loginWithFacebookErrorMessage = useSelector(state => state?.LoginWithFacebookReducer?.loginWithFacebookErrorMessage);
  // const loginWithFacebookMessage = useSelector(state => state?.LoginWithFacebookReducer?.loginWithFacebookMessage);
  const loginWithFacebookLoading = useSelector(state => state?.LoginWithFacebookReducer?.loginWithFacebookLoading);
  // const loginWithFacebookData = useSelector(state => state?.LoginWithFacebookReducer?.loginWithFacebookData);
  const loginWithFacebookSuccess = useSelector(state => state?.LoginWithFacebookReducer?.loginWithFacebookSuccess);
  const loginWithFacebookError = useSelector(state => state?.LoginWithFacebookReducer?.loginWithFacebookError);


  //*________Don't Remove this code ________* 29/09/2023

  // useEffect(() => {
  //   getFacebookLoginStatus().then((response) => {
  //     // if (response == null) {
  //     //   console.log("No login status for the person");
  //     // } else {
  //     //   console.log("getFacebookLoginStatus-----", response);
  //     // }
  //   });
  // }, []);

  useEffect(() => {
    if (success && isLoginSucess === false) {
      setIsLoginSuccess(true);
      let getRole = user?.user?.role
      toast.success("Login successfully.");
      if (String(getRole) === ROLE_TYPES.HOST) { navigate("/host/order"); } else { navigate("/info"); }
    } else {
      // setIsLoginSuccess(false);
      toast.error(loginError);
    }
  }, [success]);

  useEffect(() => {
    if (loginWithFacebookSuccess && !loginWithFacebookLoading) {
      setIsLoginSuccess(true);
      toast.success("Login successfully.");
      navigate("/info");
    } else {
      // setIsLoginSuccess(false);
      toast.error(loginError);
    }
  }, [loginWithFacebookSuccess]);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
      setAlertMessage(loginError);
      resetForm({});
      dispatch(loginResetState());
    }
  }, [error, loginError]);

  useEffect(() => {
    toast.error(loginWithFacebookErrorMessage);
  }, [loginWithFacebookError]);

  useEffect(() => {
    if (isUserNotFound === true) {
      toast.error("User is not verified yet.");
      navigate("/usernotfound");
    }
  }, [isUserNotFound]);

  //*Normal User Login 
  const onClickLoginUser = async () => {
    let dataObj = {
      "username": values?.email,
      "password": values?.userPassword,
    };
    dispatch(loginUser(dataObj));
  };

  //* Login With Facebook 
  const facebookLogin = async () => {
    fbLogin().then((response) => {
      // console.log("response------", JSON.stringify(response));
      if (response?.status === "connected") {
        testAPI();
        // fbLogout();
      } else {
        // something
      }
    });
  }

  function testAPI(userId) {
    fbGetUserDetails().then((userDetailResponse) => {
      // console.log(userDetailResponse);
      let firstName = userDetailResponse?.first_name
      let lastName = userDetailResponse?.last_name
      let facebookUserId = userDetailResponse?.id
      let email = userDetailResponse?.email
      let dataObj = {
        "first_name": firstName,
        "last_name": lastName,
        "fb_id": facebookUserId,
        "email": email,
      }
      dispatch(loginWithFacebook(dataObj));
    });
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <section
        className="login-wrapper py-wrapper"
        style={{ backgroundImage: "url(images/login-bg.webp)" }}
      >
        <form className="container z1" onSubmit={handleSubmit}>
          <div className="bg-transLight login-block">
            <div className="bg-lightTheme1">
              <h1>Login</h1>
              <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
                <Alert.Heading>{alertMessage}</Alert.Heading>
              </Alert>
              <div className="row justify-content-center gx-4 gy-1 mb-md-5 mb-4 login-radio">
                <div className="col-auto">
                  <label className="mb-0">Login as</label>
                </div>
                <div className="col-auto">
                  <div className="form-check form-check-inline form-checkLarge mb-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="option1"
                      defaultChecked
                      onClick={() => {
                        setSelectUser(ROLE_TYPES.HOST)
                      }}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Host
                    </label>
                  </div>
                  <div className="form-check form-check-inline form-checkLarge mb-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="option2"
                      onClick={() => {
                        setSelectUser(ROLE_TYPES.RENTER)
                      }}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Renter
                    </label>
                  </div>
                </div>
              </div>
              <div className="text-start mb-md-5 mb-4">
                <div className="mb-30">
                  <label htmlFor="user_name">
                    EMAIL OR PHONE NUMBER{" "}
                    <label style={{ color: "#FF0000" }}> * </label>
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={(e) => {
                      handleChange(e);
                      let checkPhoneNumber = phoneRegex.test(e.target.value);
                      if (checkPhoneNumber) { handleChange("isEmail")('0') } else { handleChange("isEmail")('1') }
                    }}
                    onBlur={handleBlur}
                    className={errors.email && touched.email ? 'form-control form-control-error' : 'form-control'}
                    placeholder="Email or phone number"
                    maxLength={30}
                  />
                  {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                </div>
                <div>
                  <label htmlFor="password">
                    PASSWORD <label style={{ color: "#FF0000" }}> * </label>
                  </label>
                  <div className="password-input position-relative d-flex">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="userPassword"
                      value={values.userPassword}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      placeholder="Password"
                      className={errors.userPassword && touched.userPassword ? 'form-control form-control-error' : 'form-control'}
                    />
                    <div className="toogleIcon">
                      <span
                        className="toggle-password"
                        onClick={togglePasswordVisibility}
                      >
                        <i className={showPassword ? 'fas fa-eye fs-5' : 'fas fa-eye-slash fs-5'}></i>
                      </span>
                    </div>
                  </div>
                  {errors.userPassword && touched.userPassword ? <p className="error-text">{errors.userPassword}</p> : null}
                  <Link to={"/forgotpassword"} className="a-link"
                    onClick={() => {
                      dispatch(forgotPasswordReset());
                    }}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-green"
              >
                <span>LOGIN</span>
              </button>
              <p>
                Don't have an account ? {" "}
                <Link onClick={() => {
                  dispatch(resendOTPReset());
                  dispatch(verifyOtpReset());
                }} to={"/register"} className="a-link" >
                  Register
                </Link>
              </p>
              <Link onClick={() => {
                facebookLogin();
              }} className="btn btn-facebook">
                <span className="flex-all">
                  <i className="fa-brands fa-facebook"></i>
                  LOGIN WITH FACEBOOK
                </span>
              </Link>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Login;
