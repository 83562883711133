import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { renterOrderList } from "../../../../Redux/renter/orderList/action"
import ProfileCard from '../../../Pages/Renter/ProfileCard';
import { renterUpdateReviewDetailsReset } from "../../../../Redux/renter/updateReview/action"
import { renterAddReviewDetailsReset } from "../../../../Redux/renter/addReview/action"
import { signRenterAgreementRenterReset } from "../../../../Redux/renter/updateRenterSignAgreement/action"
import { clearLocalStorage } from '../../../../helpers/commonfunction'
import { ORDER_STATUS, ORDER_PAYMENT_STATUS } from '../../../../helpers/enum'


const RenterViewOrder = () => {

  const dispatch = useDispatch();
  const navigation = useNavigate();

  //*List
  const [orderList, setOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isShowLoader, setIsShowLoader] = useState(false);

  const OrderError = useSelector(state => state?.RenterOrderReducer?.OrderError);
  const loading = useSelector(state => state?.RenterOrderReducer?.loading);
  const orderListData = useSelector(state => state?.RenterOrderReducer?.orderListData);
  const isOrderSuccess = useSelector(state => state?.RenterOrderReducer?.isOrderSuccess);
  const isOrderError = useSelector(state => state?.RenterOrderReducer?.isOrderError);

  useEffect(() => {
    let dataObj = { "page": currentPage }
    setIsShowLoader(false);
    dispatch(renterOrderList(dataObj));
  }, [currentPage])

  useEffect(() => {
    if (!loading && orderListData) {
      var orderListResponse = orderListData?.finalArray;
      if (orderListResponse.length > 0) {
        setIsShowLoader(true);
        setOrderList(orderListResponse);
      } else {
        setIsShowLoader(true);
        setOrderList([]);
      }
    }
  }, [isOrderSuccess]);

  //*expire token
  useEffect(() => {
    if (isOrderError && !loading) {
      if (OrderError === "Request failed with status code 401") {
        navigation("/login");
        clearLocalStorage();
        toast.error('JWT Expire.');
      }
    }
  }, [isOrderError, OrderError]);

  //* Navigate to order Details
  const onClickOrderDetails = (orderId, productId) => {
    localStorage.setItem("orderIdForRenter", orderId);
    localStorage.setItem("productIdForRenter", productId);
    navigation("/renter/orderdetails");
    dispatch(renterUpdateReviewDetailsReset());
    dispatch(renterAddReviewDetailsReset());
  };

  return (
    <div>
      <section className="innerBanner-wrapper">
        <div className="innerBanner-img img-obj cover">
          <img src="images/header.png" alt="header_image" />
        </div>
        <div className="container h-100 z1 flex-all">
          <h1>Orders</h1>
        </div>
      </section>
      <section className="profile-wrapper py32-wrapper profile-order-btn px-18 order-not-found">
        <div className="container-fluid">
          <div className="row g-4">

            <ProfileCard />
            <div className="col-md-9 col-sm-8 profile-main">
              <div className="card ms-xl-1">
                <div className="card-header">
                  <h3>ORDERS</h3>
                </div>
                <div className="card-body">
                  {isShowLoader === false &&
                    <div id="loader" data-wordload="Please Wait"></div>
                  }
                  <div className="table-responsive mb-4">
                    <table className="table-themeBr table-orders">
                      <thead>
                        <tr>
                          <th>ORDER ID</th>
                          <th>PRODUCT NAME</th>
                          <th>HOST NAME</th>
                          <th>DATE</th>
                          <th>PRICE ($)</th>
                          <th>ORDER STATUS</th>
                          <th>PAYMENT STATUS</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>

                      {
                        orderList.map((item, index) => {
                          return (
                            orderList.length > 0 ?
                              <tbody key={index}>
                                <tr >
                                  <td>{item?.orderID}</td>
                                  <td><span className="text-nowrap text-dark render-wrap-text">{item?.productName}</span></td>
                                  <td><span className="text-nowrap text-dark render-wrap-text">{item?.userName}</span></td>
                                  <td>{item?.date}</td>
                                  <td>
                                    ${item?.price}<span>/ Day</span>
                                  </td>
                                  {
                                    item?.orderStatus === ORDER_STATUS.PENDING ?
                                      <td>
                                        <div className="status status-progress">Deposit Amount Paid</div>
                                      </td> :
                                      item?.orderStatus === ORDER_STATUS.ACCEPTED ?
                                        <td>
                                          <div className="status status-accept">Accepted</div>
                                        </td> :
                                        item?.orderStatus === ORDER_STATUS.REJECTED ?
                                          <td>
                                            <div className="status status-danger">Rejected</div>
                                          </td> :
                                          item?.orderStatus === ORDER_STATUS.RENTER_AGREEMENT ?
                                            <td>
                                              <div className="status status-accept">Renter Agreement</div>
                                            </td> :
                                            item?.orderStatus === ORDER_STATUS.CHECK_OUT ?
                                              <td>
                                                <div className="status status-checkout">Check out</div>
                                              </td> :
                                              item?.orderStatus === ORDER_STATUS.FINAL_AMOUNT_AUTO_DEDUCT ?
                                                <td>
                                                  <div className="status status-success">Final Amount Auto Deduct</div>
                                                </td> :
                                                item?.orderStatus === ORDER_STATUS.CHECK_IN ?
                                                  <td>
                                                    <div className="status status-checkin">Check in</div>
                                                  </td> :
                                                  item?.orderStatus === ORDER_STATUS.RETURN_DEPOSIT_ACCEPTED ?
                                                    <td>
                                                      <div className="status status-success">Deposit Accepted</div>
                                                    </td> :
                                                    item?.orderStatus === ORDER_STATUS.RETURN_DEPOSIT_REJECTED ?
                                                      <td>
                                                        <div className="status status-danger">Deposit Rejected</div>
                                                      </td> :
                                                      <td>
                                                        <div className="status status-success">Final Amount Paid</div>
                                                      </td>
                                  }
                                  {
                                    item?.paymentStatus === ORDER_PAYMENT_STATUS.PAID ?
                                      <td>
                                        <div className="status status-success">Paid</div>
                                      </td> :
                                      item?.paymentStatus === ORDER_PAYMENT_STATUS.SECURITY_DEPOSIT_REFUNDED ?
                                        <td>
                                          <div className="status status-progress">Security Deposit Refunded</div>
                                        </td> :
                                        item?.paymentStatus === ORDER_PAYMENT_STATUS.SUCCEEDED ?
                                          <td>
                                            <div className="status status-success">Succeeded</div>
                                          </td> :
                                          <td>
                                            <div className="status status-danger">Pending</div>
                                          </td>
                                  }
                                  <td>
                                    <button onClick={() => {
                                      localStorage.setItem("userIdRenter", item?.userId);
                                      dispatch(signRenterAgreementRenterReset());
                                      onClickOrderDetails(item?.realOrderID, item?.productId);
                                    }} className="btn btn-green btn-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22.061 21.828"
                                      >
                                        <path
                                          d="M17,3a2.828,2.828,0,0,1,4,4L7.5,20.5,2,22l1.5-5.5Z"
                                          transform="translate(-1 -1.172)"
                                          fill="none"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                        />
                                      </svg>
                                    </button>
                                  </td>
                                </tr>
                              </tbody> :
                              <div className="heading flex center">
                                <h2>
                                  {"No data available"}
                                </h2>
                              </div>
                          )
                        })
                      }
                    </table>
                  </div>
                  {
                    orderList.length > 0 &&
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li
                          className={`page-item ${!orderListData?.hasNextPage ? "disabled" : ""
                            }`}
                        >
                          <button
                            className="page-link prev-link"
                            aria-label="Previous"
                            onClick={() => setCurrentPage(currentPage - 1)}
                          >
                            <span aria-hidden="true">
                              <i className="fa-solid fa-chevron-left"></i>
                            </span>
                          </button>
                        </li>
                        {Array.from({ length: orderListData?.totalPages }, (_, index) => (
                          <li
                            key={index}
                            className={`page-item ${currentPage === index + 1 ? "active" : ""
                              }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => setCurrentPage(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                        <li
                          className={`page-item ${!orderListData?.hasNextPage ? "disabled" : ""
                            }`}
                        >
                          <button
                            className="page-link next-link"
                            aria-label="Next"
                            onClick={() => setCurrentPage(currentPage + 1)}
                          >
                            <span aria-hidden="true">
                              <i className="fa-solid fa-chevron-right"></i>
                            </span>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RenterViewOrder;
