import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProfileCard from "../Pages/Renter/ProfileCard";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  hostGetOrderDetails
} from "../../Redux/host/orderDetails/action";
import {
  updateOrderStatus
} from "../../Redux/updateOrderStatus/action";
import {
  sendMessageDetails,
  sendMessageDetailsReset,
} from "../../Redux/chat/send_Message/action";
import {
  fetchMessageDetails
} from "../../Redux/chat/fetch_Message/action";
import {
  signRenterAgreementHost
} from "../../Redux/host/updateRenterSignAgreement/action";
import { getPromoCodeDetails } from "../../Redux/actions";
import { clearLocalStorage } from "../../helpers/commonfunction";
import moment from "moment";
import { toast } from "react-toastify";
import { api } from "../../config";
import * as url from "../../helpers/url_helper";
import { API_CODE } from "../../helpers/enum";
import * as jQuery from 'jquery';
import Parser from 'html-react-parser';
import { ORDER_STATUS, HOST_MESSAGE } from '../../helpers/enum'
import OrderProgressSteps from "./orderProgressSteps";
import Modal from 'react-modal';


// let ImageData = [];
const OrderDetails = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const chatMessagesRef = useRef(null);

  //*state
  const [insuranceTotal, setInsuranceTotal] = useState(0);
  const [securityDepositTotal, setSecurityDepositTotal] = useState(0);
  const [productName, setProductName] = useState("");
  const [productLocation, setProductLocation] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productImage, setProductImage] = useState("");
  const [isAddValue, setIsAddValue] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [productId, setProductId] = useState("");
  const [getOrderStatus, setGetOrderStatus] = useState("");
  const [loggedUserId, setLoggedUserId] = useState("");
  const [comment, setComment] = useState("");
  const [isImagUpload, setIsImagUpload] = useState(false);
  const [ImageData, setImageData] = useState([]);
  const [orderData, setOrderData] = useState(null);
  const [pickupDate, setPickupDate] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [returnTime, setReturnTime] = useState("");
  const [approximateAmount, setApproximateAmount] = useState(0);
  const [promoCodeDiscount, setPromoCodeDiscount] = useState(0);
  const [approximateFinalAmount, setApproximateFinalAmount] = useState(0);
  const [perDays, setPerDays] = useState("0");
  const [finalAmount, setFinalAmount] = useState(0);
  const [finalData, setFinalData] = useState(null);
  const [isShowLoader, setIsShowLoader] = useState(false);

  const [showConfirmation1, setShowConfirmation1] = useState(false);
  const [showConfirmation2, setShowConfirmation2] = useState(false);

  const handleReject = () => {
    setShowConfirmation1(true);
  };

  const handleButtonClick = () => {
    if (getOrderStatus === ORDER_STATUS.RETURN_AGREEMENT) {
      onClickUpdateStatus(ORDER_STATUS.AGREEMENT_REJECTED);
    } else {
      handleReject();
    }
  };

  const handleConfirmation1 = (confirmed) => {
    setShowConfirmation1(false);
    if (confirmed) {
      setShowConfirmation2(true);
    }
  };

  const handleConfirmation2 = (confirmed) => {
    setShowConfirmation2(false);
    if (confirmed) {
      window.open("https://forms.monday.com/forms/e080a54bd60be1da9efab4d8ab8f61d1?r=use1", "_blank");
      onClickUpdateStatus(ORDER_STATUS.RETURN_DEPOSIT_REJECTED);
    }
  };

  //*List
  const [reviewList, setReviewList] = useState([]);
  const [commentsArray, setCommentsArray] = useState([]);
  const [fileList, setFileList] = useState([]);
  const currentDate = moment().format('YYYY-MM-DD');
  const currentTime = moment().format('HH:mm');

  const success = useSelector(state => state?.HostOrderDetailReducer?.success);
  const orderDetailLoading = useSelector(state => state?.HostOrderDetailReducer?.orderDetailLoading);
  const orderDetailData = useSelector(state => state?.HostOrderDetailReducer?.orderDetailData);
  const error = useSelector(state => state?.HostOrderDetailReducer?.error);
  const errorMessage = useSelector(state => state?.HostOrderDetailReducer?.errorMessage);

  const statusErrorMessage = useSelector(state => state?.UpdateOrderStatusReducer?.statusErrorMessage);
  const statusLoading = useSelector(state => state?.UpdateOrderStatusReducer?.statusLoading);
  const statusSuccess = useSelector(state => state?.UpdateOrderStatusReducer?.statusSuccess);
  const statusError = useSelector(state => state?.UpdateOrderStatusReducer?.statusError);
  const updateOrderStatusData = useSelector(state => state?.UpdateOrderStatusReducer?.updateOrderStatusData);

  const sendMsgErrorMessage = useSelector(state => state?.SendMessageReducer?.sendMsgErrorMessage);
  const sendMsgLoading = useSelector(state => state?.SendMessageReducer?.sendMsgLoading);
  const sendMsgData = useSelector(state => state?.SendMessageReducer?.sendMsgData);
  const sendMsgSuccess = useSelector(state => state?.SendMessageReducer?.sendMsgSuccess);
  const sendMsgError = useSelector(state => state?.SendMessageReducer?.sendMsgError);

  const FetchMsgData = useSelector(state => state?.FetchMessageReducer?.fetchMsgData);

  const promoCodeDetailsErrorMessage = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsErrorMessage);
  const promoCodeDetailsLoading = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsLoading);
  const promoCodeDetailsSuccess = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsSuccess);
  const promoCodeDetailsError = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsError);
  const promoCodeDetailsOrderData = useSelector(state => state?.PromoCodeReducer?.promoCodeDetailsOrderData);

  const hostRenterAgreementErrorMessage = useSelector(state => state?.HostRenterAgreementReducer?.hostRenterAgreementErrorMessage);
  const hostRenterAgreementLoading = useSelector(state => state?.HostRenterAgreementReducer?.hostRenterAgreementLoading);
  const hostRenterAgreementSuccess = useSelector(state => state?.HostRenterAgreementReducer?.hostRenterAgreementSuccess);
  const hostRenterAgreementError = useSelector(state => state?.HostRenterAgreementReducer?.hostRenterAgreementError);
  const hostRenterAgreementData = useSelector(state => state?.HostRenterAgreementReducer?.hostRenterAgreementData);

  useEffect(() => {
    if (!statusLoading && statusSuccess && updateOrderStatusData) {
      // toast.success("Status updated successfully.");
      let updateOrderResponse = updateOrderStatusData?.order
      if (updateOrderResponse.order_status === ORDER_STATUS.PENDING) { toast.success(HOST_MESSAGE.ORDER_ACCEPT); }
      navigation("/host/order");
      setIsShowLoader(false);
    }
  }, [statusSuccess]);

  useEffect(() => {
    if (!statusLoading && statusError) {
      toast.error(statusErrorMessage);
    }
  }, [statusError, statusErrorMessage]);

  useEffect(() => {
    if (!sendMsgLoading && sendMsgData) {
      toast.success("Message sent successfully.");
      onClickClearStates();
    }
  }, [sendMsgSuccess]);

  useEffect(() => {
    if (!sendMsgLoading && sendMsgError) {
      toast.error(sendMsgErrorMessage);
      onClickClearStates();
    }
  }, [sendMsgError]);

  //*expire token
  useEffect(() => {
    if (
      (error && !orderDetailLoading) ||
      (statusError && !statusLoading) ||
      (sendMsgError && !sendMsgLoading)
    ) {
      if (
        errorMessage === "Request failed with status code 401" ||
        statusErrorMessage === "Request failed with status code 401" ||
        sendMsgErrorMessage === "Request failed with status code 401"
      ) {
        navigation("/login");
        clearLocalStorage();
        toast.error("JWT Expire.");
      }
    }
  }, [
    error,
    statusError,
    sendMsgError,
    errorMessage,
    statusErrorMessage,
    sendMsgErrorMessage,
  ]);

  useEffect(() => {
    let orderId = localStorage.getItem("orderId");
    setOrderId(orderId);
    let productId = localStorage.getItem("productId");
    setProductId(productId);
    if (orderId) {
      let dataObj = {
        id: orderId,
        pagination: 0,
      };
      dispatch(hostGetOrderDetails(dataObj));
    }
  }, []);

  useEffect(() => {
    if (hostRenterAgreementSuccess || hostRenterAgreementData) {
      navigation("/host/order");
      // setIsShowLoader(false);
    }
  }, [hostRenterAgreementSuccess, hostRenterAgreementData]);

  useEffect(() => {
    if (hostRenterAgreementError && hostRenterAgreementErrorMessage) {
      toast.error(hostRenterAgreementErrorMessage);
      // setIsShowLoader(false);
    }
  }, [hostRenterAgreementError]);

  useEffect(() => {
    let loggedUserId = localStorage.getItem("loggedUserId");
    let productId = localStorage.getItem("productId");
    setLoggedUserId(loggedUserId);
    if (!orderDetailLoading && orderDetailData) {
      let orderDetailResponse = orderDetailData?.order;

      if (orderDetailResponse.promo_code) {
        dispatch(getPromoCodeDetails({ promo_code: orderDetailResponse?.promo_code, type: "product" }));
      }
      setOrderData(orderDetailResponse);
      let productArray = orderDetailResponse?.product_item;
      let reviewsData = orderDetailResponse?.reviews_data;
      var reviewsDataResponse = reviewsData?.filter(
        (item) => item?.product_id === productId && item?.order_id === orderId
      );
      setReviewList(reviewsDataResponse);
      var checkHostReviewResponse = reviewsData?.filter(
        (item) => item?.renter_id === loggedUserId
      );
      if (checkHostReviewResponse.length > 0) {
        setIsAddValue(false);
      } else {
        setIsAddValue(true);
      }

      var currentProductResponse = productArray?.filter(
        (item) => item?.product_id === productId
      );

      let orderStatus = orderDetailResponse?.order_status;
      setGetOrderStatus(orderStatus);
      if (currentProductResponse.length > 0) {

        let location =
          currentProductResponse[0]?.product_data?.product_location[0];
        setProductName(currentProductResponse[0]?.product_data?.product_name);
        setProductLocation(location);
        setProductPrice(currentProductResponse[0]?.product_data?.price_per_day);
        setProductImage(
          currentProductResponse[0]?.product_data?.images_detail[0]?.path
        );

        setSecurityDepositTotal(
          currentProductResponse[0]?.product_data?.security_deposit
        );
        setPickupDate(
          moment(orderDetailResponse?.product_item[0]?.pickup_date).format(
            "MM/DD/YYYY"
          )
        );
        setPickupTime(orderDetailResponse?.product_item[0]?.pickup_time);
        setReturnDate(
          moment(orderDetailResponse?.product_item[0]?.return_date).format(
            "MM/DD/YYYY"
          )
        );
        setReturnTime(orderDetailResponse?.product_item[0]?.return_time);
        const pickupDate = new Date(
          orderDetailResponse?.product_item[0]?.pickup_date
        );
        const returnDate = new Date(
          orderDetailResponse?.product_item[0]?.return_date
        );

        pickupDate.setHours(0, 0, 0, 0);
        returnDate.setHours(0, 0, 0, 0);
        const timeDifference = returnDate.getTime() - pickupDate.getTime() + (24 * 60 * 60 * 1000);
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        const approximateFinalAmount = daysDifference * orderDetailResponse?.product_item[0]?.product_data?.price_per_day;
        setInsuranceTotal(daysDifference * currentProductResponse[0]?.product_data?.insurance_amount);

        setApproximateAmount(
          approximateFinalAmount === 0
            ? orderDetailResponse?.product_item[0]?.product_data?.price_per_day
            : approximateFinalAmount
        );

        setPromoCodeDiscount(orderDetailResponse?.discount_amount);
        // let calculateFinalAmount = (approximateFinalAmount - orderDetailResponse.discount_amount);
        let totalAmount = orderDetailResponse?.order_total_amount
        setApproximateFinalAmount(totalAmount);

        setPerDays(daysDifference === 0 ? "1" : daysDifference);
        let finalAmount =
          orderDetailResponse?.final_order_data?.final_total_amount;
        setFinalAmount(finalAmount);
        setFinalData(orderDetailResponse?.final_order_data);

      }
    }
  }, [success]);

  useEffect(() => {    
    // function to fetch and sort data
    const fetchData = () => {
      if (orderId) {
        dispatch(fetchMessageDetails(orderId));
      }
    };
  
    // perform initial fetch
    fetchData();
  
    // function to start interval if the page is visible
    const startFetching = () => {
      return setInterval(fetchData, 60000);
    };
  
    // track the visibility of the page
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        intervalId = startFetching();
      } else if (intervalId) {
        clearInterval(intervalId);
      }
    };
  
    // set up an interval ID and visibility listener
    let intervalId;
    if (document.visibilityState === "visible") {
      intervalId = startFetching();
    }
  
    // add event listener for page visibility
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    // cleanup the interval and remove listener on unmount
    return () => {
      clearInterval(intervalId);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [orderId]);
  
  useEffect(() => {
    if (FetchMsgData && Array.isArray(FetchMsgData)) {
      const sortedData = FetchMsgData.sort((a, b) => a.created - b.created);
      setCommentsArray(sortedData);
    }
  }, [FetchMsgData]); // This effect runs only when FetchMsgData changes
  
  

  //* Add Review
  const onClickAddReview = (orderId, productId) => {
    localStorage.setItem("isAdd", isAddValue);
    navigation("/host/review");
  };

  //* Update Status
  const onClickUpdateStatus = (status) => {
    var orderId = localStorage.getItem("orderId");
    let dataObj = {
      order_id: orderId,
      order_status: status,
    };
    dispatch(updateOrderStatus(dataObj));
  };

  //* Update Renter Agreement
  const onClickUpdateRenterAgreement = () => {
    toast.success(HOST_MESSAGE.AGREEMENT_SIGN);
    var orderId = localStorage.getItem("orderId");
    let dataObj = {
      order_id: orderId,
      is_Sign_Rental_Agreement_From_Host: true,
    };
    dispatch(signRenterAgreementHost(dataObj));
  };

  const onClickUpdateReview = (item) => {
    localStorage.setItem("isAdd", isAddValue);
    localStorage.setItem("reviewUpdateData", JSON.stringify(item));
    navigation("/host/review");
  };

  //* Add Comments
  const handleCaptureComments = () => {
    if (comment === "") {
      toast.error("please Enter the Comment");
      return false;
    }
    if (isImagUpload === true) {
      handleImageChange(fileList);
      sendMsgWithDoc();
      onClickClearStates();
    } else {
      let data = { message: comment, user_id: loggedUserId };
      let previousCommentData = [...commentsArray, data];
      sendMessage();
      setCommentsArray(previousCommentData);
    }
  };

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [commentsArray]);

  //*Send Message
  const sendMessage = () => {
    let orderId = localStorage.getItem("orderId");
    // let userId = localStorage.getItem("userIdHost");
    let getLoggedUserIdRole = localStorage.getItem("role");
    let userId;
    if (getLoggedUserIdRole === "host") {
      let orderDetailResponse = orderDetailData?.order;
      userId = orderDetailResponse?.user_id
    } else {
      let orderDetailResponse = orderDetailData?.order;
      userId = orderDetailResponse?.product_item[0]?.product_data.host_detail._id
    }
    let dataObj = {
      // user_id: userId,
      orderId: orderId,
      message: comment,
      // "proof_docs": "",
    };
    // dataObj.message = removeHtmlTags(comment);
    dispatch(sendMessageDetails(dataObj));
    scrollToBottom();
  };

  function removeHtmlTags(html) {
    var txt = jQuery(html).text().replaceAll("\n\n", "\n");
    return html;
  }

  const allFiles = [];
  function uploadAdapterNew(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            setIsImagUpload(true);
            allFiles.push(file);
            setFileList(allFiles);
            resolve(true);
          });
        });
      },
    };
  }

  //*Handle Image
  const handleImageChange = (file) => {
    if (file && file.length) {
      //append images
      for (let [key, itm] of Object.entries(file)) {
        const reader = new FileReader();
        reader.onload = () => {
          let data = { item: reader.result, type: "image" };
          setImageData(ImageData.push(data));
          setCommentsArray(ImageData);
        };
        reader.readAsDataURL(itm);
      }
    }
  };

  function uploadPluginNew(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapterNew(loader);
    };
  }

  //*clear States
  const onClickClearStates = () => {
    dispatch(sendMessageDetailsReset());
    setComment("");
    setIsImagUpload(false);
  };

  const sendMsgWithDoc = () => {
    let dataAppend = new FormData();
    let orderId = localStorage.getItem("orderId");
    // let userId = localStorage.getItem("userIdHost");
    let token = localStorage.getItem("accessToken");
    let headers = {};

    let getLoggedUserIdRole = localStorage.getItem("role");
    let userId;
    if (getLoggedUserIdRole === "host") {
      let orderDetailResponse = orderDetailData?.order;
      userId = orderDetailResponse?.user_id
    } else {
      let orderDetailResponse = orderDetailData?.order;
      userId = orderDetailResponse?.product_item[0]?.product_data?.host_detail?._id
    }

    let dataObj = {
      user_id: userId,
      order_id: orderId,
      message: comment,
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    //append images
    for (let [key, itm] of Object.entries(fileList)) {
      dataAppend.append("proof_docs", itm, itm?.name);
    }

    //append remaining data
    for (let [key, itm] of Object.entries(dataObj)) {
      if (key !== "proof_docs") {
        dataAppend.append(key, itm);
      }
    }

    var requestOptions = {
      method: "POST",
      headers,
      body: dataAppend,
      redirect: "follow",
    };

    setImageData([]);
    fetch(`${api.API_URL}${url?.POST_SEND_MESSAGE}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //need to redirect to login page
        if (result?.statusCode === API_CODE.SUCCESS) {
          toast.success("Message sent successfully.");
          onClickClearStates();
        }
      })
      .catch((error) => console.log("error", error));
  };

  function extractTextFromHTML(htmlString) {
    // Create a temporary div element
    const tempDiv = document.createElement('div');
    // Set the innerHTML of the div to the HTML string
    tempDiv.innerHTML = htmlString;
    // Get the text content of the div
    const textContent = tempDiv.textContent;
    return textContent;
  }

  return (
    <div>
      <section className="innerBanner-wrapper">
        <div className="innerBanner-img img-obj cover">
          <img src="images/header.png" alt="header_image" />
        </div>
        <div className="container h-100 z1 flex-all">
          <h1>Order Details</h1>
        </div>
      </section>
      {/* Profile */}

      <section className="profile-wrapper py32-wrapper profile-order-btn px-18">
        <div className="container-fluid">
          {isShowLoader === true &&
            <div id="loader" data-wordload="Please Wait"></div>
          }
          <div className="row g-4">
            <ProfileCard />
            <div className="col-md-9 col-sm-8 profile-main">
              <OrderProgressSteps dataFromParent={orderData?.order_progress_step} />
              {
                orderDetailData?.order?.order_status === ORDER_STATUS.RENTER_AGREEMENT ?
                  <h4 >{HOST_MESSAGE.CHECKOUT}</h4> :
                  orderDetailData?.order?.order_status === ORDER_STATUS.CHECK_IN ?
                    <h4>{HOST_MESSAGE.CHECKIN}</h4> :
                    orderDetailData?.order?.order_status === ORDER_STATUS.RETURN_DEPOSIT_ACCEPTED ?
                      <h4>{HOST_MESSAGE.ORDER_APPROVE}</h4> :
                      orderDetailData?.order?.order_status === ORDER_STATUS.RETURN_DEPOSIT_REJECTED ?
                        <h4 >{HOST_MESSAGE.ORDER_REJECT}</h4> : null
              }

              {
                // currentDate <= moment(pickupDate).format('YYYY-MM-DD') && currentTime <= pickupTime ?
                currentDate <= moment(pickupDate).format('YYYY-MM-DD') ?
                  <>
                    {getOrderStatus === ORDER_STATUS.PENDING && (
                      <div className="row">
                        <div className="col-lg-12 col-sm-12 col-md-12">
                          <div className="mb-2 mx-3 accept-btn float-end">
                            <Link
                              onClick={() => {
                                setIsShowLoader(true);
                                if (getOrderStatus === ORDER_STATUS.PENDING) {
                                  onClickUpdateStatus(ORDER_STATUS.ACCEPTED);
                                }
                                else if (getOrderStatus === ORDER_STATUS.CHECK_IN) {
                                  onClickUpdateStatus(ORDER_STATUS.RETURN_DEPOSIT_ACCEPTED);
                                }
                              }}
                              className="btn btn-green"
                            >
                              <span>{"Accept"}</span>
                            </Link>

                            {
                              <button
                                onClick={() => {
                                  setIsShowLoader(true);
                                  if (getOrderStatus === ORDER_STATUS.PENDING) {
                                    onClickUpdateStatus(ORDER_STATUS.REJECTED);
                                  }
                                  else if (getOrderStatus === ORDER_STATUS.RETURN_AGREEMENT) {
                                    onClickUpdateStatus(ORDER_STATUS.AGREEMENT_REJECTED);
                                  } else if (getOrderStatus === ORDER_STATUS.CHECK_IN) {
                                    onClickUpdateStatus(ORDER_STATUS.RETURN_DEPOSIT_REJECTED);
                                  }
                                }}
                                className="btn btn-black mx-2"
                              >
                                <span>{"Reject"}</span>
                              </button>
                            }
                          </div>
                        </div>
                      </div>
                    )}
                  </> : null
              }
              {
                getOrderStatus === ORDER_STATUS.ACCEPTED ?
                  <div className="row">
                    <div className="col-lg-12 col-sm-12 col-md-12">
                      <div className="mb-2 agrement-btn float-end">
                        <Link
                          onClick={() => {
                            // setIsShowLoader(true);
                            onClickUpdateRenterAgreement();
                          }}
                          to="https://docuseal.co/d/GUBFrSRZUwVaaJ"
                          className="btn btn-green"
                          target="_blank"
                        >
                          <span>{"Sign Rental Agreement"}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  : null
              }
              {
                getOrderStatus === ORDER_STATUS.RETURN_AGREEMENT || getOrderStatus === ORDER_STATUS.CHECK_IN ?
                  <div className="row">
                    <div className="col-lg-12 col-sm-12 col-md-12">
                      <div className="mb-2 mx-3 accept-btn float-end">
                        <Link
                          onClick={() => {
                            setIsShowLoader(true);
                            if (getOrderStatus === ORDER_STATUS.CHECK_IN) {
                              onClickUpdateStatus(ORDER_STATUS.RETURN_DEPOSIT_ACCEPTED);
                            }
                          }}
                          className="btn btn-green"
                        >
                          <span>{"Accept"}</span>
                        </Link>
                        <button onClick={handleButtonClick}
                          className="btn btn-black mx-2"><span>{"Reject"}</span></button>
                        <Modal isOpen={showConfirmation1}
                          style={{
                            content: {
                              width: "400px", height: "300px", top: "50%", left: "50%", transform: "translate(-50%,-50%)", display: "flex", flexDirection: "column",
                              alignItems: "center", justifyContent: "center",
                            },
                          }}
                          onRequestClose={() => handleConfirmation1(false)}>
                          <h2>Are you sure?</h2>
                          <button onClick={() => handleConfirmation1(true)} className="btn btn-green">
                            <span>{"Yes, Reject"}</span></button>

                          <button onClick={() => handleConfirmation1(false)} className="btn btn-warning my-2"><span>{"Nevermind"}</span></button>
                        </Modal>
                        <Modal
                          style={{
                            content: {
                              width: "500px", height: "450px", top: "50%", left: "50%", transform: "translate(-50%,-50%)", display: "flex", flexDirection: "column",
                              alignItems: "center", justifyContent: "center",
                            },
                          }}
                          isOpen={showConfirmation2}
                          onRequestClose={() => handleConfirmation2(false)}>
                          <h2>Confirmation Message:</h2>
                          <p>
                            By rejecting this check in, you will be required to submit a claim and are reporting damage to OutdoorShare made from the renter.</p>
                          <ul>
                            <li style={{ listStyle: "disc" }}>The renter's security deposit will not be returned until we investigate the damage reported in full.</li>
                            <li style={{ listStyle: "disc" }}>You as the host will not be paid until the claim is made and resolved.</li>
                          </ul>
                          <p><b>Are you sure you want to reject this check in?</b></p>
                          <button className="btn btn-green" onClick={() => handleConfirmation2(true)}><span>{"Yes, Reject"}</span></button>
                          <button className="btn btn-warning my-2" onClick={() => handleConfirmation2(false)}><span>{"Nevermind"}</span></button>
                        </Modal>
                        {/* <button
                          onClick={() => {
                            setIsShowLoader(true);
                            if (getOrderStatus === ORDER_STATUS.RETURN_AGREEMENT) {
                              onClickUpdateStatus(ORDER_STATUS.REJECTED);
                            } else if (getOrderStatus === ORDER_STATUS.CHECK_IN) {
                              onClickUpdateStatus(ORDER_STATUS.RETURN_DEPOSIT_REJECTED);
                            }
                          }}
                          className="btn btn-black mx-2"
                        >
                          <span>{"Reject"}</span>
                        </button> */}
                      </div>
                    </div>
                  </div> : null
              }
              {/* <hr /> */}
              <div className="table-responsive mb-5 bg-lightTheme p-3 w-100">
                <table className="table-themeBr">
                  <tbody>
                    <tr>
                      <th>Order Id</th>
                      <td># {orderData?._id}</td>
                      <th>Order Reference Id</th>
                      <td># {orderData?.client_reference_id}</td>
                    </tr>
                    <tr>
                      <th>INITIAL TOTAL:</th>
                      {/* <td>{"$ " + orderData?.order_total_amount / 100 + " / $ " + approximateAmount}</td> */}
                      <td>{"$ " + (orderData?.order_total_amount.toFixed(2)) + " / $ " + approximateAmount.toFixed(2)}</td>
                      <th>Payment Status</th>
                      <td>{orderData?.payment_status}</td>
                    </tr>
                    {
                      orderData?.is_delivery && (
                        <>
                          <tr>
                            <th>Delivery Address</th>
                            <td>{orderData?.delivery_address}</td>
                            <th>Delivery Distance</th>
                            <td>{orderData?.delivery_distance}</td>
                          </tr>
                          <tr>
                            <th>Delivery Charge Per Mile</th>
                            <td>$ {orderData?.product_item[0]?.product_data?.delivery_charge.toFixed(2)}</td>
                            <th>{"Total Delivery Amount ( $" + orderData?.product_item[0]?.product_data?.delivery_charge + " * " + orderData?.delivery_distance + ")"}</th>
                            <td>$ {orderData?.delivery_charge.toFixed(2)}</td>
                          </tr>
                        </>
                      )}
                  </tbody>
                </table>
              </div>
              <div className="card ms-xl-1">
                <div className="card-header">
                  <h3>Deposit Order Details</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col cart-main">
                      <div className="table-responsive">
                        <table className="table-themeBr table-cart">
                          <thead>
                            <tr>
                              <th>PRODUCT</th>
                              <th>LOCATION</th>
                              <th>PICK UP DATE/TIME</th>
                              <th>RETURN DATE/TIME</th>
                              <th>APPROXIMATE AMOUNT</th>
                              <th>PRICE</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="d-inline-flex align-items-center">
                                  <div className="ratio"><img src={productImage} alt="prdt_img" /></div>
                                  {productName.length > 15 ? (
                                    <td className="product-break-word">{productName}</td>
                                  ) : (
                                    <h5>{productName}</h5>
                                  )}
                                </div>
                              </td>
                              <td>{productLocation}</td>
                              <td>{pickupDate} : {moment(pickupTime, "HH:mm").format('h:mm A')}</td>
                              <td>{returnDate} : {moment(returnTime, "HH:mm").format('h:mm A')}</td>
                              <td>${approximateAmount + " for " + perDays + " Days"} </td>
                              <td>
                                ${productPrice}
                                <span>/ Day</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div></div>
                    </div>
                    <div className="col-md-auto">
                      <div className="price-details mt-2">
                        <h5>PRICE DETAILS</h5>
                        <div className="table-responsive mb-3">
                          <table className="table-themeBr">
                            <tbody>

                              <tr>
                                <th>REFUNDABLE DEPOSIT:</th>
                                <td>${securityDepositTotal.toFixed(2)}</td>
                              </tr>
                              <tr>
                                <th>TOTAL:</th>
                                <th>
                                  $ {securityDepositTotal.toFixed(2)}
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card ms-xl-1 mt-4">
                <div className="card-header">
                  <h3>Final Details</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="table-responsive mb-5 bg-lightTheme p-3">
                      <table className="table-themeBr">
                        <tbody>
                          <tr>
                            <th>Order Id</th>
                            <td># {orderData?._id}</td>
                            <th>Final Order Reference Id</th>
                            <td># {finalData?.client_reference_id}</td>
                          </tr>
                          <tr>
                            <th>Final Total</th>
                            <td>
                              {finalData?.final_total_amount
                                ? `$ ${finalData?.final_total_amount / 100}`
                                : "N/A"}
                            </td>
                            <th>Final Payment Status</th>
                            <td>{finalData?.payment_status ? finalData?.payment_status : "N/A"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col cart-main">
                      <div className="table-responsive">
                        <table className="table-themeBr table-cart">
                          <thead>
                            <tr>
                              <th>PRODUCT</th>
                              <th>LOCATION</th>
                              <th>PICK UP DATE/TIME</th>
                              <th>RETURN DATE/TIME</th>
                              <th>APPROXIMATE AMOUNT</th>
                              <th>PRICE</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="d-inline-flex align-items-center">
                                  <div className="ratio"><img src={productImage} alt="fnl_prdt_img" /></div>
                                  {productName.length > 15 ? (
                                    <td className="product-break-word">{productName}</td>
                                  ) : (
                                    <td>{productName}</td>
                                  )}
                                </div>

                              </td>
                              <td>{productLocation}</td>
                              <td>{pickupDate} : {moment(pickupTime, "HH:mm").format('h:mm A')}</td>
                              <td>{returnDate} : {moment(returnTime, "HH:mm").format('h:mm A')}</td>
                              <td>
                                $
                                {approximateAmount +
                                  " for " +
                                  perDays +
                                  " Days"}{" "}
                              </td>
                              <td>
                                ${productPrice}
                                <span>/ Day</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div></div>
                    </div>
                    <div className="col-md-auto">
                      <div className="price-details mt-2">
                        <h5>Final Amount Details</h5>
                        <div className="table-responsive mb-3">
                          <table className="table-themeBr">
                            <tbody>
                              {
                                orderData?.is_delivery &&
                                <tr>
                                  <th>
                                    {
                                      `DELIVERY CHARGE PER MILE ( $ ${orderData?.product_item[0]?.product_data?.delivery_charge.toFixed(2)} )`
                                    }
                                  </th>
                                  <td>${orderData?.delivery_charge.toFixed(2)}</td>
                                </tr>
                              }
                              <tr>
                                <th>{"Protection plan : ($" + orderDetailData?.order?.product_item[0]?.product_data.insurance_amount + ") a day" + " * (" + perDays + ")"}</th>
                                <td>${insuranceTotal.toFixed(2)}</td>
                              </tr>
                              <tr>
                                <th>{"Amount per day : ($" + orderDetailData?.order?.product_item[0]?.product_data.price_per_day + ")" + " * (" + perDays + ")"}</th>
                                <td>${(approximateAmount > 0) ? approximateAmount.toFixed(2) : ""}</td>
                              </tr>
                              <tr>
                                {
                                  promoCodeDetailsOrderData?.promocode?.discount ?
                                    <th>Promo Code Discount ( {promoCodeDetailsOrderData?.promocode?.discount} %  ) :</th> :
                                    <th>Promo Code Discount ( {"0"} %  ) :</th>
                                }
                                <td>${(promoCodeDiscount > 0) ? promoCodeDiscount.toFixed(2) : "0"}</td>
                              </tr>
                              <tr>
                                <th>Final Amount:</th>
                                <td>${(approximateFinalAmount > 0) ? approximateFinalAmount.toFixed(2) : ""}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {finalAmount && (
                          <div className="text-center">
                            <h5>Final Amount Paid</h5>
                            <h4>
                              $
                              {finalAmount
                                ? (finalAmount / 100).toFixed(2)
                                : ""}
                            </h4>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="reviews-wrapper pyCustom-wrapper bg-lightTheme ">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="row">
                <div className="col-md-1 col-5">
                  <h3 className="mb-5">Reviews</h3>
                </div>
                <div className="col-md-11 col-7">
                  {
                    isAddValue && getOrderStatus === ORDER_STATUS.RETURN_DEPOSIT_ACCEPTED || getOrderStatus === ORDER_STATUS.RETURN_DEPOSIT_REJECTED ?
                      <div className="text-center">
                        <button
                          onClick={() => {
                            onClickAddReview();
                          }}
                          className="btn btn-green mt-3"
                        >
                          <span>{"Add Review"}</span>
                        </button>
                      </div> : null
                  }
                </div>
              </div>
              {reviewList.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                    key={index}
                  >
                    <div className="reviews-block">
                      <div>
                        <div className="rating">
                          {[1, 2, 3, 4, 5].map((starValue) => (
                            <i
                              key={starValue}
                              className={`fa-solid fa-star ${starValue <= item?.rate ? "selected" : ""
                                }`}
                            ></i>
                          ))}
                        </div>
                        <h6>{item?.text}</h6>
                      </div>
                    </div>
                    {item?.renter_id === loggedUserId && (
                      <div>
                        <button
                          onClick={() => {
                            onClickUpdateReview(item);
                          }}
                          className="svg-order btn-green btn-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22.061 21.828"
                          >
                            <path
                              d="M17,3a2.828,2.828,0,0,1,4,4L7.5,20.5,2,22l1.5-5.5Z"
                              transform="translate(-1 -1.172)"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="col-lg-7">
              {
                <div className="chat-box">
                  <div className="chat-header">
                    <h2>Chat with renter</h2>
                  </div>
                  <div className="chat-messages" ref={chatMessagesRef}>
                    {commentsArray?.map((comment, index) => {
                      return (
                          <div
                            key={index}
                            className={`message ${comment.user_id === loggedUserId ? "right" : "left"}`}
                          >
                            <div className="message-content">
                              {Parser(comment?.message)}
                            </div>
                            <div className="text-muted small" style={{fontSize: '11px'}}>
                              {moment(comment.created).calendar(null, {
                                sameDay: '[Today,] h:mm A',
                                lastDay: '[Yesterday,] h:mm A',
                                lastWeek: 'ddd, h:mm A',
                                sameElse: function(now) {
                                  return Math.abs(moment().diff(comment.created, 'months')) > 6 
                                    ? 'DD MMM YYYY, h:mm A'
                                    : 'ddd, DD MMM, h:mm A';
                                }
                              })}
                            </div>
                          </div>
                      );
                    })}
                  </div>
                  <div className="chat-input row g-2">
                    {/* <textarea
                    style={{ width: "100%" }}
                    rows="3"
                    placeholder="Type your message..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  /> */}
                    <div className="col-lg-10">
                      {
                        <CKEditor
                          config={{
                            toolbar: [],
                            extraPlugins: [uploadPluginNew],
                          }}
                          editor={ClassicEditor}
                          data={comment}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            // console.log('Editor is ready to use!', editor);
                          }}
                          onChange={(event, editor) => {
                            /* const data = editor.getData();
                        ImageData = [{ "item": data, "type": "text" }];
                        setComment(data); */
                            // console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            const data = editor.getData();
                            const stringWithoutPTags = extractTextFromHTML(data);
                            setImageData([{ item: stringWithoutPTags, type: "text" }]);
                            setComment(stringWithoutPTags);
                            // console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            // console.log('Focus.', editor.data.get());
                          }}
                        />
                      }
                    </div>
                    <div className="col-lg-2 cke-btn">
                      <button
                        className="btn-green cke-send-btn"
                        onClick={handleCaptureComments}
                        disabled={sendMsgLoading}
                      >
                        {sendMsgLoading ? "Sending..." : "Send"}
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
            {/* <div className="col-md-1"></div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderDetails;
