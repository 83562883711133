import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
    SIGN_RENTER_AGREEMENT_HOST,
} from "./actionTypes";
import { signRenterAgreementHostSuccessful, signRenterAgreementHostFailed, } from "./action";
import { postUpdateOrderStatusSignRenterAgreementForHost, } from "../../../helpers/fakebackend_helper"
import { API_CODE } from '../../../helpers/enum'


function* getHostAgreementDetails({ payload: { user } }) {

    try {
        const response = yield call(postUpdateOrderStatusSignRenterAgreementForHost, user);
        if (response.statusCode === API_CODE.SUCCESS) {
            yield put(signRenterAgreementHostSuccessful(response.data));
        } else {
            yield put(signRenterAgreementHostFailed(response.message));
        }
    } catch (error) {
        yield put(signRenterAgreementHostFailed(error));
    }
}

export function* watchOnGetHostRenterDetails() {
    yield takeEvery(SIGN_RENTER_AGREEMENT_HOST, getHostAgreementDetails);
}

function* HostOrderRenterAgreementSaga() {

    yield all([
        fork(watchOnGetHostRenterDetails),
    ]);
}

export default HostOrderRenterAgreementSaga;
