import {
    SIGN_RENTER_AGREEMENT_RENTER,
    SIGN_RENTER_AGREEMENT_RENTER_SUCCESSFUL,
    SIGN_RENTER_AGREEMENT_RENTER_FAILED,
    SIGN_RENTER_AGREEMENT_RENTER_RESET,
} from "./actionTypes";


export const signRenterAgreementRenter = user => {
    return {
        type: SIGN_RENTER_AGREEMENT_RENTER,
        payload: { user },
    }
}

export const signRenterAgreementRenterSuccessful = user => {
    return {
        type: SIGN_RENTER_AGREEMENT_RENTER_SUCCESSFUL,
        payload: user,
    }
}

export const signRenterAgreementRenterFailed = user => {
    return {
        type: SIGN_RENTER_AGREEMENT_RENTER_FAILED,
        payload: user,
    }
}

export const signRenterAgreementRenterReset = user => {
    return {
        type: SIGN_RENTER_AGREEMENT_RENTER_RESET,
        payload: user,
    }
}
