import {
    SIGN_RENTER_AGREEMENT_HOST,
    SIGN_RENTER_AGREEMENT_HOST_SUCCESSFUL,
    SIGN_RENTER_AGREEMENT_HOST_FAILED,
    SIGN_RENTER_AGREEMENT_HOST_RESET
} from "./actionTypes";


export const signRenterAgreementHost = user => {
    return {
        type: SIGN_RENTER_AGREEMENT_HOST,
        payload: { user },
    }
}

export const signRenterAgreementHostSuccessful = user => {
    return {
        type: SIGN_RENTER_AGREEMENT_HOST_SUCCESSFUL,
        payload: user,
    }
}

export const signRenterAgreementHostFailed = user => {
    return {
        type: SIGN_RENTER_AGREEMENT_HOST_FAILED,
        payload: user,
    }
}

export const signRenterAgreementHostReset = user => {
    return {
        type: SIGN_RENTER_AGREEMENT_HOST_RESET,
        payload: user,
    }
}
